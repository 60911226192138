import React from 'react';
import PageNotFound from '../components/NotFound/PageNotFound';


function NotFound() {



  return (
    <>
      <PageNotFound />
    </>
  );
}

export default NotFound;
